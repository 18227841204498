/**
 * Ajuste decimal de um numero.
 * https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/Math/round
 *
 * @param	{String}	type	O tipo de arredondamento.
 * @param	{Number}	value	O numero a arredondar.
 * @param	{Integer}	exp		O expoente (o logaritmo decimal da base pretendida).
 * @returns	{Number}			O valor depois de ajustado.
 */
function decimalAdjust(type, value, exp) {
  // Se exp eh indefinido ou zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // Se o valor nao eh um numero ou o exp nao eh inteiro...
  if (Number.isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Transformando para string
  value = value.toString().split('e');
  value = Math[type](+`${value[0]}e${value[1] ? +value[1] - exp : -exp}`);
  // Transformando de volta
  value = value.toString().split('e');
  return +`${value[0]}e${value[1] ? +value[1] + exp : exp}`;
}

export function round10(value, exp) {
  // Arredondamento decimal
  return decimalAdjust('round', value, exp);
}

export function floor10(value, exp) {
  // Decimal arredondado para baixo
  return decimalAdjust('floor', value, exp);
}

export function ceil10(value, exp) {
  // Decimal arredondado para cima
  return decimalAdjust('ceil', value, exp);
}
