import { Routes, Route } from 'react-router-dom';

import AuthLayout from '~/layouts/Auth';
import Page404 from '~/pages/Page404';

import {
  auth as authRoutes,
  landing as landingRoutes,
  dashboard as dashboardRoutes,
} from './index';
import renderRoutes from './renderRoutes';

function AppRoutes() {
  return (
    <Routes>
      {renderRoutes(authRoutes)}
      {renderRoutes(landingRoutes)}
      {renderRoutes(dashboardRoutes)}

      <Route
        path="*"
        element={
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        }
      />
    </Routes>
  );
}

export default AppRoutes;
