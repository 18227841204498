import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function useCheckUserAuthentication({
  isPrivate,
  isPublic,
  path = '',
}) {
  const navigate = useNavigate();

  const { signed, token, selectedCompany } = useSelector(state => state.auth);

  const redirectUserTo = useCallback(() => {
    let redirectTo = '';

    if (!isPublic) {
      if ((!signed || !token) && isPrivate) {
        redirectTo = '/';
        return redirectTo;
      }

      if (
        signed &&
        token &&
        !selectedCompany &&
        path !== '/switch-company' &&
        path !== '/new-company'
      ) {
        redirectTo = '/switch-company';
        return redirectTo;
      }

      if (
        signed &&
        token &&
        selectedCompany &&
        (!isPrivate || path === '/switch-company' || path === '/new-company')
      ) {
        redirectTo = '/dashboard';
        return redirectTo;
      }
    }

    return redirectTo;
  }, [isPrivate, isPublic, path, selectedCompany, signed, token]);

  useEffect(() => {
    const redirectTo = redirectUserTo();

    if (redirectTo) {
      navigate(redirectTo);
    }
  }, [navigate, redirectUserTo]);
}
