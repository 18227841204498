import { Grid, List, ListItem, ListItemText } from '@mui/material';

import IntlMessages from '~/utils/IntlMessages';

export default function CompanyCopyright() {
  return (
    <Grid container justifyContent="flex-end" item md={3}>
      <List>
        <ListItem
          sx={{
            display: 'inline-block',
            width: 'auto',
          }}
        >
          <ListItemText
            primary={
              <IntlMessages
                id="datasmart.copyright"
                values={{ value: new Date().getFullYear() }}
              />
            }
          />
        </ListItem>
      </List>
    </Grid>
  );
}
