const userReducers = {
  // Update user
  updateUserRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: ({ newUser, resetForm }) => {
      return { payload: { newUser, resetForm } };
    },
  },
  updateUserSuccess: {
    reducer: (state, action) => {
      const { newUser } = action.payload;

      state.detail = newUser;
      state.loading = false;
    },
    prepare: ({ newUser }) => {
      return { payload: { newUser } };
    },
  },
  updateUserFailure: state => {
    state.loading = false;
  },
  // Get user detail
  getUserDetailRequest: state => {
    state.detail = {};
    state.requestError = true;
  },
  getUserDetailSuccess: {
    reducer: (state, action) => {
      const { user } = action.payload;

      state.detail = user;
      state.requestError = false;
    },
    prepare: ({ user }) => {
      return { payload: { user } };
    },
  },
  getUserDetailFailure: () => {},
  // Reset detail
  resetUserDetail: state => {
    state.detail = {};
  },
};

export default userReducers;
