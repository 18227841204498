import PropTypes from 'prop-types';

import { Box, CircularProgress } from '@mui/material';

export default function Loader({ size, color }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100%',
      }}
    >
      <CircularProgress size={size} color={color} />
    </Box>
  );
}

Loader.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

Loader.defaultProps = {
  size: 20,
  color: 'secondary',
};
