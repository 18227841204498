import enMessages from '../locales/en-US';

const enLang = {
  messages: {
    ...enMessages,
  },
  locale: 'en-US',
};

export default enLang;
