import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Box, Button, Typography, Paper } from '@mui/material';

import PageTitle from '~/components/PageTitle';

export default function Page404() {
  const { formatMessage } = useIntl();

  return (
    <Box px={2}>
      <Paper
        sx={{
          padding: 4,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
        elevation={1}
      >
        <PageTitle title={formatMessage({ id: 'page404.title' })} />

        <Typography variant="h3" component="h1" textAlign="center">
          {formatMessage({ id: 'page404.404' })}
        </Typography>

        <Typography
          variant="h6"
          component="h5"
          textAlign="center"
          marginBottom={2}
        >
          {formatMessage({ id: 'page404.title' })}
        </Typography>

        <Typography textAlign="center" marginBottom={2}>
          {formatMessage({ id: 'page404.description' })}
        </Typography>

        <Button variant="contained" color="primary" component={Link} to="/">
          {formatMessage({ id: 'page404.return-to-website' })}
        </Button>
      </Paper>
    </Box>
  );
}
