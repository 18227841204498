import { Box } from '@mui/material';

import CompanyLogo from '~/assets/images/logo/logo-company.png';

export default function SidebarFooter() {
  return (
    <Box
      sx={{
        backgroundColor: theme => theme.dashboardSidebar.footer.background,
        padding: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box component="img" src={CompanyLogo} sx={{ width: '8.75rem' }} />
    </Box>
  );
}
