import { createSlice } from '@reduxjs/toolkit';

import orderItemReducers from './orderItemReducers';

const initialState = {
  list: [],
  detail: {},

  loading: false,
  orderItemModalOpen: false,
};

const orderItemSlice = createSlice({
  name: 'orderItems',
  initialState,
  reducers: orderItemReducers,
  extraReducers: builder => {
    builder.addCase('orders/resetOrderDetail', state => {
      state.detail = {};
      state.orderItemModalOpen = false;
    });
  },
});

export const { actions: orderItemActions } = orderItemSlice;
export default orderItemSlice.reducer;
