import loadAsynchronousComponent from '~/components/loadAsynchronousComponent';
import LandingLayout from '~/layouts/Landing';

import { LandingRoute } from '../RoutesElements';

const SwitchCompany = loadAsynchronousComponent(() =>
  import('~/pages/SwitchCompany')
);

export const landingRoutes = {
  children: [
    {
      path: '/switch-company',
      component: SwitchCompany,
      element: LandingRoute,
      layout: LandingLayout,
    },
  ],
};
