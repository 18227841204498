import ptMessages from '../locales/pt-BR';

const ptLang = {
  messages: {
    ...ptMessages,
  },
  locale: 'pt-BR',
};

export default ptLang;
