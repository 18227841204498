import { Box, styled } from '@mui/material';

export const Root = styled(Box)(() => ({
  maxWidth: '32.5rem',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100%',
}));
