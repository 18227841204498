import PropTypes from 'prop-types';

import useCheckUserAuthentication from '../useCheckUserAuthentication';

export default function LandingRoute({
  layout: Layout,
  component: Component,
  path,
}) {
  useCheckUserAuthentication({
    isPublic: false,
    isPrivate: true,
    path,
  });

  return (
    <Layout>
      <Component />
    </Layout>
  );
}

LandingRoute.propTypes = {
  layout: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  path: PropTypes.string.isRequired,
};
