import { createSlice } from '@reduxjs/toolkit';

import orderReducers from './orderReducers';

const initialState = {
  list: [],
  detail: {},

  filter: {},
  filterColumnsOrder: null,
  filterSearch: null,

  requestError: false,
  loading: false,

  orderObservationModalOpen: false,
  orderFilterModalOpen: false,

  subResourceType: null,
  shouldOpenSubResourceModal: false,
};

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: orderReducers,
});

export const { actions: orderActions } = orderSlice;
export default orderSlice.reducer;
