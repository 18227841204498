import { combineReducers } from '@reduxjs/toolkit';

import auth from './auth/authSlice';
import companies from './companies/companySlice';
import orders from './orders/orderSlice';
import orderItems from './orderItems/orderItemSlice';
import productRequest from './productRequest/productRequestSlice';
import settings from './settings/settingsSlice';
import theme from './theme/themeSlice';
import user from './user/userSlice';
import userFiles from './userFiles/userFileSlice';

const rootReducer = combineReducers({
  auth,
  companies,
  orders,
  orderItems,
  productRequest,
  settings,
  theme,
  user,
  userFiles,
});

export default rootReducer;
