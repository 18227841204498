export const blueGrey = {
  900: '#2B3445',
  // Main Text
  800: '#373F50',
  // Paragraph
  700: '#4B566B',
  600: '#7D879C',
  // Low Priority form Title/Text
  500: '#AEB4BE',
  400: '#DAE1E7',
  // Border
  300: '#E3E9EF',
  200: '#F3F5F9',
  // Line Stroke
  100: '#F6F9FC',
};

export const grey = {
  900: '#212121',
  850: '#323232',
  800: '#424242',
  750: '#545454',
  700: '#616161',
  600: '#757575',
  500: '#9e9e9e',
  400: '#bdbdbd',
  300: '#e0e0e0',
  200: '#eeeeee',
  100: '#f5f5f5',
  50: '#fafafa',
};

export const primary = {
  100: '#E8E8EE',
  200: '#B9BACB',
  300: '#8A8CA8',
  400: '#5B5D85',
  500: '#141850',
  600: '#0F3460',
  700: '#101340',
  800: '#0E1138',
  900: '#0C0E30',
  main: '#3975D9',
  dark: '#101340',
};

export const secondary = {
  100: '#FCE9EC',
  200: '#F8C7CF',
  300: '#F07D90',
  400: '#EC6178',
  500: '#D23F57',
  600: '#E63E58',
  700: '#E3364E',
  800: '#DF2E44',
  900: '#D91F33',
  main: '#EC6178',
};

export const error = {
  100: '#FFEAEA',
  200: '#FFCBCB',
  300: '#FFA9A9',
  400: '#FF6D6D',
  500: '#FF5353',
  600: '#FF4C4C',
  700: '#FF4242',
  800: '#FF3939',
  900: '#FF2929',
  main: '#E94560',
};

export const success = {
  100: '#E7F9ED',
  200: '#C2F1D1',
  300: '#99E8B3',
  400: '#52D77E',
  500: '#33D067',
  600: '#2ECB5F',
  700: '#27C454',
  800: '#20BE4A',
  900: '#0B7724',
  main: 'rgb(51, 208, 103)',
};

export const blue = {
  50: '#F3F5F9',
  100: '#DBF0FE',
  200: '#B8DEFE',
  300: '#94C9FE',
  400: '#7AB6FD',
  500: '#4E97FD',
  600: '#3975D9',
  700: '#2756B6',
  800: '#183C92',
  900: '#0E2979',
  main: '#4E97FD',
  contrastText: '#FFFFFF',
};

export const marron = {
  50: '#F3F5F9',
  100: '#F6F2ED',
  200: '#F8DBD1',
  300: '#EBBCB3',
  400: '#D89C98',
  600: '#A3545C',
  700: '#883948',
  800: '#6E2438',
  900: '#5B162F',
  main: '#BE7374',
};

export const paste = {
  50: '#F5F5F5',
  100: '#DDFBF1',
  200: '#BDF7E8',
  300: '#97E8DA',
  400: '#76D2CA',
  600: '#36929A',
  700: '#257181',
  800: '#175368',
  900: '#0E3D56',
  main: '#4BB4B4',
  contrastText: '#FFFFFF',
};

export const warning = {
  100: '#FFF8E5',
  main: '#FFCD4E',
  contrastText: '#FFFFFF',
};

export const dark = {
  main: '#222',
};

export const white = {
  main: '#FFFFFF',
};

export const themeColors = {
  dark,
  grey,
  blueGrey,
  paste,
  error,
  marron,
  warning,
  success,
  secondary,
  white,
  info: blue,
  divider: blueGrey[200],
  background: {
    default: blueGrey[100],
  },
  text: {
    primary: blueGrey[900],
    secondary: blueGrey[800],
    disabled: blueGrey[400],
  },
};
