import { toast } from 'react-toastify';

import { takeLatest, call, put, all } from 'redux-saga/effects';
import api from '~/services/api';
import { isNetworkError } from '~/utils/checkNetwork';
import IntlMessages from '~/utils/IntlMessages';

import { settingsActions } from '../settings/settingsSlice';
import GlobalTypes from '../globalTypes';
import { authActions } from './authSlice';

export function* signIn({ payload }) {
  try {
    const { login, password: unencrypted_password } = payload;

    const { data } = yield call(api.post, 'sessions?type=WEB', {
      login,
      unencrypted_password,
    });

    const { token, user, companies, refresh_token } = data;

    const systemUsernames = ['admin', 'suporte', 'replicador'];

    if (companies.length === 0 && !systemUsernames.includes(user.username)) {
      yield put(authActions.signInFailure());

      toast.error(<IntlMessages id="sign-in.company-failed" />);

      return;
    }

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(
      authActions.signInSuccess({ token, user, companies, refresh_token })
    );

    if (companies.length === 1 && !systemUsernames.includes(user.username)) {
      try {
        const { data: settings } = yield call(
          api.get,
          'settings?is_enabled=true'
        );

        yield put(settingsActions.setGlobalSettingsListSuccess({ settings }));
      } catch (err) {
        toast.error(<IntlMessages id="settings.get-settings-failure" />);
      }
    }
  } catch (err) {
    yield put(authActions.signInFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="sign-in.authentication-failed" />);
  }
}

export function* signUp({ payload }) {
  try {
    const { name, username, email, password: unencrypted_password } = payload;

    yield call(api.post, 'users', {
      name,
      username,
      email,
      unencrypted_password,
    });

    yield put(authActions.signUpSuccess());

    toast.success(<IntlMessages id="sign-up.register-success" />);
  } catch (err) {
    yield put(authActions.signUpFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="sign-up.register-failed" />);
  }
}

export function* checkToken({ payload }) {
  if (!payload) {
    return;
  }

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;

    try {
      yield call(api.get, 'sessions/validation');

      try {
        const { data: settings } = yield call(
          api.get,
          'settings?is_enabled=true'
        );

        yield put(settingsActions.setGlobalSettingsListSuccess({ settings }));
      } catch (err) {
        toast.error(<IntlMessages id="settings.get-settings-failure" />);
      }
    } catch (err) {
      if (!isNetworkError(err)) {
        yield put(authActions.signOutTokenInvalid());
      }
    }
  }
}

export function* getSelectedCompanySettings({ payload }) {
  if (!payload.company.id) {
    return;
  }

  try {
    const { data: settings } = yield call(api.get, 'settings?is_enabled=true');

    yield put(settingsActions.setGlobalSettingsListSuccess({ settings }));
  } catch (err) {
    yield put(settingsActions.setGlobalSettingsListFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="settings.get-settings-failure" />);
  }
}

export function* signOut() {
  yield put(settingsActions.resetGlobalSettingList());
  yield localStorage.removeItem('persist:smart-commerce');
}

export default all([
  takeLatest(GlobalTypes.PERSIST_REHYDRATE, checkToken),
  takeLatest('auth/signInRequest', signIn),
  takeLatest('auth/signUpRequest', signUp),
  takeLatest('auth/setSelectedCompany', getSelectedCompanySettings),
  takeLatest('auth/signOut', signOut),
]);
