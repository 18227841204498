import { createSlice } from '@reduxjs/toolkit';

import themeReducers from './themeReducers';

const initialState = {
  currentTheme: 0,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: themeReducers,
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;
