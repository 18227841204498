import { all } from 'redux-saga/effects';
import authSagas from './auth/authSagas';
import companySagas from './companies/companySagas';
import orderSagas from './orders/orderSagas';
import orderItemSagas from './orderItems/orderItemSagas';
import productRequestSagas from './productRequest/productRequestSagas';
import userSagas from './user/userSagas';

export default function* rootSaga() {
  return yield all([
    authSagas,
    companySagas,
    orderSagas,
    orderItemSagas,
    productRequestSagas,
    userSagas,
  ]);
}
