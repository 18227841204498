import { themeColors, blue, white, dark, blueGrey, grey } from './colors';
import { components } from './components';
import { typography } from './typography';

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1440,
  },
};

const defaultTheme = (isDarkMode = false) => ({
  typography,
  breakpoints,
  components: {
    ...components,
  },
  palette: {
    mode: isDarkMode ? 'dark' : 'light',
    ...themeColors,
    primary: {
      ...blue,
      main: blue.main,
    },
    secondary: {
      ...blue,
      main: blue[600],
    },
    text: {
      primary: isDarkMode ? white.main : blueGrey[900],
      secondary: isDarkMode ? grey[300] : blueGrey[700],
    },
  },
  body: {
    auth: {
      background: isDarkMode ? dark.main : blue.main,
    },
    landing: {
      background: isDarkMode ? dark.main : blue.main,
    },
    dashboard: {
      background: isDarkMode ? dark.main : blueGrey[100],
    },
  },
  dashboardSidebar: {
    color: white.main,
    background: isDarkMode ? dark.main : blueGrey[900],
    header: {
      color: white.main,
      background: isDarkMode ? grey[850] : blueGrey[800],
      brand: {
        color: white.main,
      },
    },
    footer: {
      color: white.main,
      background: isDarkMode ? grey[850] : blueGrey[800],
    },
  },
  header: {
    background: isDarkMode ? grey[900] : white.main,
    color: isDarkMode ? white.main : blueGrey[800],
  },
  footer: {
    background: isDarkMode ? grey[900] : white.main,
    color: isDarkMode ? white.main : blueGrey[800],
  },
});

const themesOptions = [defaultTheme];

export default function themeOption(theme, isDarkMode = false) {
  const selectedTheme = themesOptions[theme];

  return selectedTheme(isDarkMode);
}
