const productRequestReducers = {
  // Create product request
  createProductRequestRequest: {
    reducer: state => {
      state.loading = true;
    },
    payload: ({ data }) => {
      return { payload: { data } };
    },
  },
  createProductRequestSuccess: state => {
    state.loading = false;
    state.selectedProductQuantity = null;
    state.selectedProductTitle = null;
    state.selectedProductId = null;
  },
  createProductRequestFailure: state => {
    state.loading = false;
  },
  closeProductRequestModal: state => {
    state.selectedProductQuantity = null;
    state.selectedProductTitle = null;
    state.selectedProductId = null;
  },
};

export default productRequestReducers;
