import PropTypes from 'prop-types';

import { AppBar, Grid, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CompanyTitle from './CompanyTitle';
import DrawerButton from './DrawerButton';
import UserMenu from './UserMenu';

export default function DashboardHeader({ onDrawerToggle }) {
  const theme = useTheme();

  return (
    <AppBar
      position="sticky"
      sx={{
        color: theme.header.color,
        backgroundColor: theme.header.background,
        zIndex: 100,
      }}
    >
      <Toolbar>
        <Grid container>
          <DrawerButton onDrawerToggle={onDrawerToggle} />

          <CompanyTitle />

          <UserMenu />
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

DashboardHeader.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};
