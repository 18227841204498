import { useSelector } from 'react-redux';

import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function CompanyTitle() {
  const theme = useTheme();

  const { company } = useSelector(state => state.auth);

  const companyName = company.nickname || company.name;

  return (
    <Grid
      item
      sx={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        marginLeft: 1,
      }}
    >
      <Typography
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: 'fit-content',
          color: theme.palette.text.secondary,
          [theme.breakpoints.down('md')]: {
            maxWidth: '30ch',
          },
          [theme.breakpoints.down('sm')]: {
            maxWidth: '20ch',
          },
        }}
        title={companyName}
      >
        {companyName}
      </Typography>
    </Grid>
  );
}
