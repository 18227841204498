const userFileReducers = {
  getUserFiles: {
    reducer: (state, action) => {
      const { files } = action.payload;

      const hasProfile = files.find(
        file => file.type === 'image/profile' && file.is_main === true
      );

      if (hasProfile) {
        state.detail = hasProfile;
      }

      state.list = files;
    },
    prepare: ({ files }) => {
      return { payload: { files } };
    },
  },
  resetListUserFile: state => {
    state.list = [];
  },
};

export default userFileReducers;
