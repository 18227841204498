import loadAsynchronousComponent from '~/components/loadAsynchronousComponent';
import AuthLayout from '~/layouts/Auth';

import { AuthRoute } from '../RoutesElements';

const SignIn = loadAsynchronousComponent(() => import('~/pages/SignIn'));

export const authRoutes = {
  children: [
    {
      path: '/',
      component: SignIn,
      element: AuthRoute,
      layout: AuthLayout,
    },
  ],
};
