import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { css, Global } from '@emotion/react';
import { CssBaseline } from '@mui/material';

import DashboardFooter from '~/components/Footer/DashboardFooter';
import DashboardHeader from '~/components/Header/DashboardHeader';
import DashboardSidebar from '~/components/Sidebar/DashboardSidebar';

import { Root, AppContainer, Content } from './styles';

const globalStyles = props => {
  return css`
    html,
    body,
    #root {
      height: 100%;
    }

    body {
      background: ${props.body.dashboard.background};
    }
  `;
};

export default function DashboardLayout({ children }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Root>
      <CssBaseline />
      <Global styles={globalStyles} />

      <DashboardSidebar
        open={isDrawerOpen}
        variant="temporary"
        onClose={handleDrawerToggle}
      />

      <AppContainer>
        <DashboardHeader onDrawerToggle={handleDrawerToggle} />
        <Content>{children}</Content>
        <DashboardFooter />
      </AppContainer>
    </Root>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
