import 'react-toastify/dist/ReactToastify.css';

import { IntlProvider } from 'react-intl';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { PersistGate } from 'redux-persist/integration/react';

import appLocale from '~/lang';
import Routes from '~/routes/Routes';
import { persistor } from '~/store';

import history from './services/history';
import MuiTheme from './theme';

const currentAppLocale = appLocale.pt;

function App() {
  return (
    <PersistGate persistor={persistor}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Router history={history}>
          <MuiTheme>
            <Routes />
          </MuiTheme>

          <ToastContainer theme="colored" />
        </Router>
      </IntlProvider>
    </PersistGate>
  );
}

export default App;
