import SimpleBar from 'simplebar-react';

import { styled, alpha } from '@mui/material';

const StyledScrollbar = styled(SimpleBar)(({ theme }) => ({
  overflowX: 'hidden',
  maxHeight: `100vh`,
  height: '100%',
  width: 260,
  backgroundColor: theme.dashboardSidebar.background,
  color: theme.dashboardSidebar.color,
  '& .simplebar-scrollbar': {
    '&.simplebar-visible:before': {
      opacity: 1,
    },
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[400], 0.6),
    },
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 9,
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6,
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
}));

export default StyledScrollbar;
