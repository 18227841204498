import loadAsynchronousComponent from '~/components/loadAsynchronousComponent';
import DashboardLayout from '~/layouts/Dashboard';

import { PrivateRoute } from '../RoutesElements';

const Dashboard = loadAsynchronousComponent(() => import('~/pages/Dashboard'));
const Help = loadAsynchronousComponent(() => import('~/pages/Help'));
const ProfileDetail = loadAsynchronousComponent(() =>
  import('~/pages/Profile/Detail')
);
const OrderDetail = loadAsynchronousComponent(() =>
  import('~/pages/Orders/Detail')
);

export const privateRoutes = {
  children: [
    {
      path: '/dashboard',
      component: Dashboard,
      element: PrivateRoute,
      layout: DashboardLayout,
    },
    {
      path: '/help',
      component: Help,
      element: PrivateRoute,
      layout: DashboardLayout,
    },
    {
      path: '/profile',
      component: ProfileDetail,
      element: PrivateRoute,
      layout: DashboardLayout,
    },
    {
      path: '/order/:id',
      component: OrderDetail,
      element: PrivateRoute,
      layout: DashboardLayout,
    },
  ],
};
