import { createSlice } from '@reduxjs/toolkit';

import settingsReducers from './settingsReducers';

const initialState = {
  list: [],
  globalList: [],
  detail: {},
  settingDetail: {},
  loading: false,
  requestError: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: settingsReducers,
});

export const { actions: settingsActions } = settingsSlice;
export default settingsSlice.reducer;
