import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { merge } from 'merge';
import PropTypes from 'prop-types';

import { ThemeProvider as EmotionTheme } from '@emotion/react';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';

import { setTheme } from '~/store/modules/theme/themeSlice';

import themeOption from './variants';

export default function MuiTheme({ children }) {
  const dispatch = useDispatch();

  const theme = useSelector(state => state.theme);

  useEffect(() => {
    const themeCode = process.env.REACT_APP_THEME || 0;

    dispatch(setTheme(themeCode));
  }, [dispatch]);

  let currentTheme = createTheme(
    merge(
      {},
      {
        ...themeOption(theme.currentTheme, false),
      }
    )
  );

  currentTheme = responsiveFontSizes(currentTheme);

  // Theme shadows
  currentTheme.shadows[1] = '0px 1px 3px rgba(3, 0, 71, 0.09)';
  currentTheme.shadows[2] = '0px 4px 16px rgba(43, 52, 69, 0.1)';
  currentTheme.shadows[3] = '0px 8px 45px rgba(3, 0, 71, 0.09)';
  currentTheme.shadows[4] = '0px 0px 28px rgba(3, 0, 71, 0.01)';

  return (
    <ThemeProvider theme={currentTheme}>
      <EmotionTheme theme={currentTheme}>{children}</EmotionTheme>
    </ThemeProvider>
  );
}

MuiTheme.propTypes = {
  children: PropTypes.node.isRequired,
};
