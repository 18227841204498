import React from 'react';

import PropTypes from 'prop-types';

import { css, Global } from '@emotion/react';
import { CssBaseline } from '@mui/material';

import { Root } from './styles';

const globalStyles = props => {
  return css`
    html,
    body,
    #root {
      height: 100%;
    }

    body {
      background: ${props.body.landing.background};
    }
  `;
};

export default function LandingLayout({ children }) {
  return (
    <>
      <CssBaseline />
      <Global styles={globalStyles} />
      <Root>{children}</Root>
    </>
  );
}

LandingLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
