import { createSlice } from '@reduxjs/toolkit';

import userFileReducers from './userFileReducer';

const initialState = {
  list: [],
  detail: {},
  loading: false,
};

const userFileSlice = createSlice({
  name: 'userFiles',
  initialState,
  reducers: userFileReducers,
  extraReducers: builder => {
    builder.addCase('users/resetUserDetail', state => {
      state.detail = {};
    });
  },
});

export const { actions: userFileActions } = userFileSlice;
export default userFileSlice.reducer;
