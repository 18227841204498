import get from 'lodash/get';
import { persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export default reducers => {
  const formName = 'auth';

  const formTransform = createTransform(
    inboundState => {
      return {
        ...inboundState,
        loading: get(inboundState, `${formName}.loading`),
        signUpModalOpen: get(inboundState, `${formName}.signUpModalOpen`),
      };
    },
    outboundState => {
      return outboundState;
    },
    { whitelist: [`auth`] }
  );

  const persistConfig = {
    key: 'smart-commerce',
    storage,
    whitelist: ['auth'],
    transforms: [formTransform],
  };

  const persistedReducer = persistReducer(persistConfig, reducers);

  return persistedReducer;
};
