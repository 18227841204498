import { Grid, List, Paper } from '@mui/material';

import FooterLink from './FooterLink';
import CompanyCopyright from './CompanyCopyright';

export default function DashboardFooter() {
  const privacityAndServiceTermsUrl =
    'https://datasmart.com.br/politica-de-privacidade/';

  return (
    <Paper
      sx={theme => ({
        backgroundColor: theme.footer.background,
        borderRadius: 0,
      })}
    >
      <Grid
        container
        sx={{
          paddingLeft: 1,
          paddingRight: 1,
        }}
      >
        <Grid container item sx={{ flex: 1, flexDirection: 'row' }} md={9}>
          <List sx={{ display: { xs: 'none', md: 'flex' } }}>
            <FooterLink to="/help" label="footer.support" />
            <FooterLink to="/help" label="footer.help-center" />
            <FooterLink
              to={privacityAndServiceTermsUrl}
              label="footer.privacy-and-terms-of-service"
              target="_blank"
            />
          </List>
        </Grid>

        <CompanyCopyright />
      </Grid>
    </Paper>
  );
}
