import { grey, white } from './colors';
import { fontFamily, fontSize } from './typography';

// ========================================================

// =========================================================

export const components = {
  MuiCssBaseline: {
    styleOverrides: theme => {
      const isDarkMode = theme.palette.mode === 'dark';

      return {
        html: {
          scrollBehavior: 'smooth',
        },
        p: {
          lineHeight: 1.75,
          color: isDarkMode ? theme.palette.primary.contrastText : 'inherit',
        },
        button: {
          fontFamily,
          fontSize,
        },
        '.MuiRating-sizeSmall': {
          fontSize: '20px',
        },
        a: {
          textDecoration: 'none',
          color: 'inherit',
        },
        ul: {
          margin: 0,
          padding: 0,
          listStyle: 'none',
        },
        '.swal2-container': {
          zIndex: '2000 !important',
        },
        'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
          {
            WebkitTextFillColor: isDarkMode
              ? `${white.main} !important`
              : 'inherit',
            WebkitBoxShadow: isDarkMode
              ? 'inset 0 0 0 1000px rgba(20,20,20,0.9) !important'
              : 'inset 0 0 0 1000px #ffffff !important',

            borderRadius: '8px !important',
          },
        // TODO: Talvez não seja necessário
        // '#nprogress .bar': {
        //   overflow: 'hidden',
        //   height: '3px !important',
        //   zIndex: '99999999 !important',
        //   background: `${theme.palette.primary.main} !important`,
        // },
      };
    },
  },

  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: 8,
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
      },
    },
  },
  MuiPagination: {
    defaultProps: {
      variant: 'outlined',
      color: 'primary',
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        '& .secondary': {
          opacity: 0.4,
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      size: 'small',
      variant: 'outlined',
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        const isDarkMode = theme.palette.mode === 'dark';

        return {
          '& input': {
            color: grey[900],
          },

          '& label': {
            color: grey[500],
          },

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: isDarkMode ? grey[700] : grey[400],
            borderRadius: '8px',
          },

          ...(ownerState.color === 'info' && {
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              fontWeight: 600,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: grey[300],
            },
          }),
        };
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: () => {
        return {
          color: grey[500],
        };
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: ({ theme }) => {
        const isDarkMode = theme.palette.mode === 'dark';

        return {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: isDarkMode ? grey[700] : grey[400],
            borderRadius: '8px',
          },

          color: grey[900],
          label: {
            color: grey[500],
          },
        };
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: () => ({
        borderColor: grey[400],
      }),
    },
  },
  MuiButton: {
    defaultProps: {
      color: 'inherit',
      disableRipple: true,
      disableElevation: true,
    },
    variants: [
      {
        props: { variant: 'contained-reversible' },
        style: {
          backgroundColor: grey[750],
          color: white.main,
          '&:hover': {
            backgroundColor: grey[800],
          },
          '&:disabled': {
            backgroundColor: 'rgba(0,0,0,0.12)',
            color: 'rgba(0,0,0,0.26)',
          },
          ':focus': {
            outline: `2px solid ${grey[900]}`,
          },
        },
      },
    ],
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        const isDarkMode = theme.palette.mode === 'dark';

        return {
          minWidth: 100,
          minHeight: 0,
          textTransform: 'none',
          transition: 'all 0.3s',
          borderRadius: '8px',
          lineHeight: 'inherit',
          ':focus': {
            outline: `2px solid ${theme.palette.primary[900]}`,
          },

          // Change the color of button to white on darkmode
          ...(isDarkMode && {
            color: white.main,
          }),

          // Change the color & background of button on darkmode
          // affects only variant=info and color=primary
          ...(isDarkMode &&
            ownerState.variant === 'text' &&
            ownerState.color === 'primary' && {
              color: grey[400],
              ':hover': {
                color: grey[200],
              },
            }),

          // Change the color & background of button on darkmode
          // affects only variant=info and color=dark|secondary
          ...(isDarkMode &&
            ownerState.variant === 'text' &&
            (ownerState.color === 'secondary' ||
              ownerState.color === 'dark') && {
              color: grey[500],
              ':hover': {
                color: grey[200],
              },
            }),

          fontWeight: 400,
        };
      },
      sizeLarge: {
        padding: '.6rem 2.5rem',
      },
    },
  },
};
