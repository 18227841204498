import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  Logout,
  Help,
  Language,
  CompareArrows,
  Person,
} from '@mui/icons-material';
import { Grid, Box, IconButton, Avatar, MenuItem } from '@mui/material';

import userAvatar from '~/assets/avatar/avatar-user.png';
import { authActions } from '~/store/modules/auth/authSlice';
import IntlMessages from '~/utils/IntlMessages';

import Menu from './Menu';

export default function UserMenu() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { company, user } = useSelector(state => state.auth);

  const handleOpenMenu = event => setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);

  const handleHelpPage = () => navigate('/help');

  const handleProfile = () => navigate('/profile');

  const handleCompanyPublicProfile = () => {
    setAnchorEl(null);

    window.open(
      `${process.env.REACT_APP_COMPANY_PUBLIC_PROFILE}/profile/${company.slug}`
    );
  };

  const handleSwitchCompany = () => {
    setAnchorEl(null);
    dispatch(authActions.clearSelectedCompany());
  };

  const handleSignOut = () => {
    setAnchorEl(null);
    dispatch(authActions.signOut());
  };

  const userProfileAvatar =
    user.files?.length > 0 ? user.files[0].url : userAvatar;

  return (
    <Grid item>
      <Box>
        <IconButton
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          aria-controls={open ? 'user-menu' : undefined}
          onClick={handleOpenMenu}
        >
          <Avatar
            alt={formatMessage({ id: 'header.user-avatar' })}
            src={userProfileAvatar}
          />
        </IconButton>

        <Menu anchorEl={anchorEl} open={open} handleCloseMenu={handleCloseMenu}>
          <MenuItem onClick={handleProfile}>
            <Person sx={{ marginRight: 1 }} />
            <IntlMessages id="header.profile" />
          </MenuItem>

          <MenuItem onClick={handleSwitchCompany}>
            <CompareArrows sx={{ marginRight: 1 }} />
            <IntlMessages id="header.switch-company" />
          </MenuItem>

          {company.slug && (
            <MenuItem onClick={handleCompanyPublicProfile}>
              <Language sx={{ marginRight: 1 }} />
              <IntlMessages id="header.company-public-profile" />
            </MenuItem>
          )}
          <MenuItem onClick={handleHelpPage}>
            <Help sx={{ marginRight: 1 }} />
            <IntlMessages id="header.help" />
          </MenuItem>

          <MenuItem onClick={handleSignOut}>
            <Logout sx={{ marginRight: 1 }} />
            <IntlMessages id="header.sign-out" />
          </MenuItem>
        </Menu>
      </Box>
    </Grid>
  );
}
