const orderReducers = {
  // Orders list
  getOrdersRequest: {
    reducer: state => {
      state.loading = true;
      state.requestError = true;
    },
    prepare: ({
      queryParams,
      modalFilters,
      filterColumnsOrder,
      filterSearch = '',
    }) => {
      return {
        payload: {
          queryParams,
          modalFilters,
          filterColumnsOrder,
          filterSearch,
        },
      };
    },
  },
  getOrdersSuccess: {
    reducer: (state, action) => {
      const { data, modalFilters, filterColumnsOrder, filterSearch } =
        action.payload;

      state.list = data;
      state.loading = false;
      state.requestError = false;
      state.orderFilterModalOpen = false;

      if (filterColumnsOrder) {
        state.filterColumnsOrder = {
          field: filterColumnsOrder.field,
          direction: filterColumnsOrder.direction,
        };
      }

      if (typeof filterSearch !== 'undefined') {
        state.filterSearch = filterSearch;
      }

      if (typeof modalFilters !== 'undefined') {
        state.filter = modalFilters;
      }
    },
    prepare: ({ data, modalFilters, filterColumnsOrder, filterSearch }) => {
      return {
        payload: {
          data,
          modalFilters,
          filterColumnsOrder,
          filterSearch,
        },
      };
    },
  },
  getOrdersFailure: state => {
    state.loading = false;
    state.requestError = true;
    state.list = [];
  },
  // Create order
  createOrderRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: ({ order, handleNavigateOnCreate, subResourceType = null }) => {
      return { payload: { order, handleNavigateOnCreate, subResourceType } };
    },
  },
  createOrderSuccess: {
    reducer: (state, action) => {
      const { order } = action.payload;

      state.list.unshift(order);
      state.loading = false;
    },
    prepare: ({ order }) => {
      return { payload: { order } };
    },
  },
  createOrderFailure: state => {
    state.loading = false;
  },
  // Udpate order
  updateOrderRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: ({
      id,
      newOrder,
      updatingItem = false,
      updatingDetail = false,
    }) => {
      return { payload: { id, newOrder, updatingDetail, updatingItem } };
    },
  },
  updateOrderSuccess: {
    reducer: (state, action) => {
      const { newOrder } = action.payload;

      state.detail = newOrder;
      state.list = state.list.map(order => {
        return order.id === newOrder.id ? newOrder : order;
      });

      state.orderObservationModalOpen = false;
      state.loading = false;
    },
    prepare: ({ newOrder }) => {
      return { payload: { newOrder } };
    },
  },
  updateOrderFailure: state => {
    state.loading = false;
  },
  // Delete order
  deleteOrderRequest: {
    reducer: state => {
      state.loading = true;
    },
    prepare: ({ id }) => {
      return { payload: { id } };
    },
  },
  deleteOrderSuccess: {
    reducer: (state, action) => {
      const { id } = action.payload;

      state.list = state.list.filter(order => {
        return order.id !== id;
      });
      state.loading = false;
    },
    prepare: ({ id }) => {
      return { payload: { id } };
    },
  },
  deleteOrderFailure: state => {
    state.loading = false;
  },
  // Get order detail
  getOrderDetailRequest: {
    reducer: state => {
      state.detail = {};
      state.requestError = true;
    },
    prepare: ({ id }) => {
      return { payload: { id } };
    },
  },
  getOrderDetailSuccess: {
    reducer: (state, action) => {
      const { order } = action.payload;

      state.detail = order;
      state.requestError = false;
    },
    prepare: ({ order }) => {
      return { payload: { order } };
    },
  },
  getOrderDetailFailure: () => {},
  // Reset list
  resetOrderList: state => {
    state.list = [];
    state.requestError = false;
    state.loading = false;
    state.detail = {};
    state.orderFilterModalOpen = false;

    state.filter = {};
    state.filterColumnsOrder = null;
    state.filterSearch = '';
  },
  // Reset detail
  resetOrderDetail: state => {
    state.detail = {};
    state.orderObservationModalOpen = false;
  },
  // Observation modal
  openOrderObservationModal: state => {
    state.orderObservationModalOpen = true;
  },
  closeOrderObservationModal: state => {
    state.orderObservationModalOpen = false;
  },
  // Saving on subresource
  setSavingOrderOnSubResource: {
    reducer: (state, action) => {
      const { subResourceType } = action.payload;

      state.shouldOpenSubResourceModal = true;
      state.subResourceType = subResourceType;
    },
    prepare: ({ subResourceType }) => {
      return { payload: { subResourceType } };
    },
  },
  resetSubResourceData: state => {
    state.shouldOpenSubResourceModal = false;
    state.subResourceType = null;
  },
  // Filter modal
  openOrderFilterModal: state => {
    state.orderFilterModalOpen = true;
  },
  closeOrderFilterModal: state => {
    state.orderFilterModalOpen = false;
  },
  // Disable order create/update
  disableOrderCreation: state => {
    state.requestError = true;
  },
};

export default orderReducers;
