import { authRoutes } from './categories/auth';
import { landingRoutes } from './categories/landing';
import { movementsRoutes } from './categories/movements';
import { privateRoutes } from './categories/private';

const others = [movementsRoutes];

export const auth = [authRoutes];

export const landing = [landingRoutes];

export const dashboard = [privateRoutes, ...others];

export default others;
