module.exports = {
  // Addresses
  'addresses.title': 'Endereços',
  'addresses.new-title': 'Novo endereço',
  'addresses.edit-title': 'Edita endereço',

  'addresses.postal-code': 'CEP',
  'addresses.address': 'Endereço',
  'addresses.number': 'Número',
  'addresses.complement': 'Complemento',
  'addresses.neighborhood': 'Bairro',
  'addresses.city': 'Cidade',
  'addresses.state': 'Estado',

  'addresses.error-postal-code':
    'Erro ao consultar o CEP, verifique os dados! 🤔',

  'addresses.type': 'Tipo',
  'addresses.types.general': 'Geral',
  'addresses.types.billing': 'Cobrança',
  'addresses.types.delivery': 'Entrega',
  'addresses.types.branch-office': 'Filial',
  'addresses.types.headquarters': 'Matriz',
  'addresses.types.residential': 'Residencial',
  'addresses.types.work': 'Trabalho',

  'addresses.is-main': 'Principal',
  'addresses.is-main-address': 'Endereço principal',
  'addresses.error-delete-is-main':
    'Endereço principal não pode ser excluído quando tem mais de um! 🤔',
  'addresses.google-maps': 'Ver no Google Maps',

  // Buttons
  'buttons.add': 'Novo',
  'buttons.save': 'Salvar',
  'buttons.cancel': 'Cancelar',
  'buttons.ok': 'OK',
  'buttons.close': 'Fechar',
  'buttons.clear': 'Limpar',
  'buttons.delete': 'Excluir',
  'buttons.settlement': 'Baixar',
  'buttons.hide': 'Esconder',
  'buttons.go-back': 'Voltar',
  'buttons.change': 'Alterar',
  'buttons.continue': 'Continuar',
  'buttons.print': 'Imprimir',
  'buttons.copy': 'Copiar',
  'buttons.update': 'Atualizar',
  'buttons.select': 'Selecionar',
  'buttons.download-app': 'Baixar App',
  'buttons.share': 'Compartilhar',
  'buttons.catalog': 'Catálogo',
  'buttons.filter': 'Filtrar',
  'buttons.recalculate': 'Recalcular',
  'buttons.send': 'Enviar',
  'buttons.reset': 'Redefinir',
  'buttons.remove': 'Remover',
  'buttons.view': 'Visualizar',
  'buttons.search': 'Pesquisar',
  'buttons.save-and-new': 'Salvar e novo',
  'buttons.confirm': 'Confirmar',
  'buttons.agreed': 'Aceito',
  'buttons.register': 'Registrar',

  // Company
  'company.title': 'Empresa',

  'company.user.name': 'Nome',
  'company.user.email': 'Email',

  'company.type.main': 'Principal',
  'company.type.parent': 'Matriz',
  'company.type.subsidiary': 'Filial',

  'company.disabled-company': 'Empresa desativada',

  'company.situation.normal': 'Normal',
  'company.situation.canceled': 'Cancelado',

  // Dashboard
  'dashboard.title': 'Início',

  'dashboard.welcome': 'Seja bem-vindo, ',
  'dashboard.logged-company': 'Você está logado na empresa ',

  'dashboard.message.failed-to-load-user-company-name':
    'Falha ao carregar o nome da empresa do usuário!',

  // Datasmart
  'datasmart.name': 'Datasmart',
  'datasmart.copyright': '© {value} - Datasmart',
  'datasmart.website': 'https://www.datasmart.com.br',
  'datasmart.smartcommerce': 'Smart E-Commerce',

  'datasmart.email-company.email': 'datasmart@datasmart.com.br',
  'datasmart.email-company.contact': 'Assuntos gerais',
  'datasmart.email-company.subject-general-message': 'Assuntos gerais',

  'datasmart.email-commercial.email': 'comercial@datasmart.com.br',
  'datasmart.email-commercial.contact': 'Comercial',
  'datasmart.email-commercial.subject-commercial-message':
    'Assuntos comerciais',

  'datasmart.email-support.email': 'suporte@datasmart.com.br',
  'datasmart.email-support.contact': 'Suporte',
  'datasmart.email-support.subject-support-message': 'Suporte',

  'datasmart.phone-fixed.contact': 'Assuntos gerais',
  'datasmart.phone-fixed.number.avare': '1437119090',
  'datasmart.phone-fixed.ddi': '55',
  'datasmart.phone-fixed.operator': 'Fixo',

  'datasmart.phone-mobile.contact': 'Suporte',
  'datasmart.phone-mobile.number': '14996641191',
  'datasmart.phone-mobile.number.lencois-paulista': '14997616504',
  'datasmart.phone-mobile.ddi': '55',
  'datasmart.phone-mobile.operator': 'Vivo',
  'datasmart.phone-mobile.whatsapp-message':
    'Olá, eu estou precisando da ajuda do suporte Datasmart. Você poderia me ajudar?',

  'datasmart.address.title': 'Endereço',
  'datasmart.address.state': 'SP',

  // Datasmart - Avare Address
  'datasmart.address.city.avare': 'Avaré',
  'datasmart.address.address-location.avare': 'Rua Alagoas',
  'datasmart.address.number.avare': '1633',
  'datasmart.address.neighborhood.avare': 'Centro',
  'datasmart.address.postal-code.avare': '18705-070',

  // Datasmart - Lencois Paulista Address
  'datasmart.address.city.lencois-paulista': 'Lençóis Paulista',
  'datasmart.address.address-location.lencois-paulista':
    'Avenida Geraldo Pereira de Barros',
  'datasmart.address.number.lencois-paulista': '336',
  'datasmart.address.neighborhood.lencois-paulista': 'Centro',
  'datasmart.address.postal-code.lencois-paulista': '18682-041',

  // Dialogs
  'dialogs.delete-title': 'Deseja excluir este registro?',
  'dialogs.delete-text':
    'Esta ação não poderá ser desfeita após sua confirmação!',

  'dialogs.data-loss-title': 'Deseja continuar?',
  'dialogs.data-loss-text':
    'Existem alterações que ainda não foram salvas e serão perdidas caso continue.',

  // Documents
  'documents.cpf': 'CPF',
  'documents.cpf-data': 'CPF: {value}',
  'documents.valid-cpf': 'CPF válido',
  'documents.invalid-cpf': 'CPF inválido',

  'documents.cnpj': 'CNPJ',
  'documents.cnpj-data': 'CNPJ: {value}',
  'documents.valid-cnpj': 'CNPJ válido',
  'documents.invalid-cnpj': 'CNPJ inválido',

  'documents.cpf-cnpj': 'CPF/CNPJ',

  // Emails
  'emails.title': 'Emails',
  'emails.new-title': 'Novo email',
  'emails.edit-title': 'Edita email',

  'emails.is-main': 'Principal',
  'emails.is-main-email': 'Email principal',
  'emails.error-delete-is-main':
    'Email principal não pode ser excluído quando tem mais de um! 🤔',

  'emails.email': 'Email',
  'emails.contact': 'Contato',
  'emails.send': 'Enviar email para ',

  // Footer
  'footer.support': 'Suporte',
  'footer.help-center': 'Central de Ajuda',
  'footer.privacy-and-terms-of-service': 'Privacidade e Termos de Serviço',

  // Forms
  'forms.informations': 'Informações',

  'forms.field-excluded': 'Excluído',
  'forms.field-disabled': 'Desativado',

  'forms.input.required-field': 'Campo obrigatório',
  'forms.input.valid-email': 'O campo precisa ser um email válido',

  // Forms - autocomplete
  'forms.autocomplete.close': 'Fechar',
  'forms.autocomplete.open': 'Abrir',
  'forms.autocomplete.clear': 'Apagar',
  'forms.autocomplete.no-options': 'Nenhuma opção',
  'forms.autocomplete.loading': 'Carregando...',

  // Forms - input length
  'forms.input.field-greater-than': 'Campo deve ser maior que {value}',

  'forms.input.field-greater-than-or-equal-to':
    'Campo deve ser maior ou igual a {value}',

  // Header
  'header.search': 'Pesquisar',

  'header.profile': 'Meu perfil',
  'header.switch-company': 'Trocar de empresa',
  'header.company-public-profile': 'Perfil público da empresa',
  'header.help': 'Ajuda',
  'header.sign-out': 'Sair',
  'header.user-avatar': 'Avatar do usuário',
  'header.open-drawer': 'Abrir o menu',

  // Help
  'help.title': 'Ajuda',

  'help.contact-title': 'Fale conosco',
  'help.contact-description':
    'Caso precisar de ajuda, entre em contato conosco através de um de nossos canais.',

  // Material Table
  'material-table.add': 'Adicionar',
  'material-table.view-and-edit': 'Ver e editar',
  'material-table.print': 'Imprimir',
  'material-table.refresh': 'Atualizar',
  'material-table.edit': 'Editar',
  'material-table.download': 'Download',
  'material-table.settlement': 'Baixar',
  'material-table.delete': 'Deletar',
  'material-table.share': 'Compartilhar',
  'material-table.view': 'Ver',
  'material-table.filter': 'Filtrar',

  // Messages
  'messages.preposition.female': 'da',
  'messages.preposition.male': 'do',
  'messages.conjuction.and': 'e',

  'messages.get-failure':
    'Falha ao carregar os registros, atualize a página! 😢',

  'messages.save-success': 'Cadastrado com sucesso! 🎉',
  'messages.save-failure': 'Falha ao salvar o registro, tente novamente! 😢',

  'messages.edit-success': 'Editado com sucesso! 🎉',
  'messages.edit-failure': 'Falha ao editar o registro, tente novamente! 😢',

  'messages.change-success': 'Alterado com sucesso! 🎉',
  'messages.change-failure': 'Falha ao alterar o registro, tente novamente! 😢',

  'messages.update-success': 'Atualizado com sucesso! 🎉',
  'messages.update-failure':
    'Falha ao atualizar o registro, tente novamente! 😢',

  'messages.delete-success': 'Excluído com sucesso! 🎉',
  'messages.delete-failure': 'Falha ao excluir o registro, tente novamente! 😢',

  'messages.settlement-success': 'Baixado com sucesso! 🎉',
  'messages.settlement-failure':
    'Falha ao baixar o registro, tente novamente! 😢',

  'messages.get-details-failure':
    'Falha ao carregar os detalhes do registro, atualize a página! 😢',

  'messages.notice-title': 'Aviso',

  // Messages - Internal error
  'messages.error-500.without-connection-server':
    'Sem conexão com o servidor! 🤔',
  'messages.error-500.internal-server-error': 'Erro interno no servidor! 🤔',
  'messages.error-500.database-connection-error':
    'Erro interno no servidor! Código: 001 🤔',
  'messages.error-504.gateway-timeout':
    'Erro interno no servidor! Código: 002 🤔',

  // Order Items
  'order-items.title': 'Itens',
  'order-items.new-title': 'Novo item',
  'order-items.edit-title': 'Edita item',

  'order-items.description': 'Descrição',
  'order-items.product': 'Produto',
  'order-items.unit-measure': 'Unidade de medida',
  'order-items.unit-value': 'Valor unitário',
  'order-items.discount-percentage': '% de desconto',
  'order-items.discount-value': 'Valor de desconto',
  'order-items.unit-value-discount-value': 'Valor unitário / Desconto',
  'order-items.net-value': 'Valor líquido',
  'order-items.quantity': 'Quantidade',
  'order-items.total': 'Total',
  'order-items.observation': 'Observação',
  'order-items.stock-available': 'Disponível',
  'order-items.stock-unavailable': 'Indisponível',

  'order-items.situation': 'Situação',
  'order-items.situation.normal': 'Normal',
  'order-items.situation.blocked': 'Bloqueado',
  'order-items.situation.canceled': 'Cancelado',
  'order-items.situation.undefined': 'Indefinido',

  'order-items.product-request.title': 'Pedido de produto',

  'order-items.product-request.priority': 'Prioridade',
  'order-items.product-request.priority.low': 'Baixa',
  'order-items.product-request.priority.medium': 'Média',
  'order-items.product-request.priority.high': 'Alta',

  'order-items.invalid-net-value-check': 'Erro ao calcular o preço líquido! 🤔',

  'order-items.message.quantity-change':
    'Para alterar a quantidade, é necessário excluir este item e lançar novamente.',
  'order-items.message.item-total-exceeds-payment-method-maximum-amount':
    'A soma do item com o total existente do registro excede o valor máximo do método de pagamento!',

  'order-items.discount-percentage-readjusted':
    'O percentual de desconto será reajustado pois o valor do desconto ultrapassa 2 casas decimais! 🤔',

  // Orders
  'orders.title': 'Pedidos',
  'orders.new-title': 'Novo pedido',

  'orders.request-number': 'Número do pedido',
  'orders.payment-method': 'Método de pagamento',
  'orders.registered-at': 'Data de registro',
  'orders.total': 'Total',
  'orders.total-products': 'Total de produtos',
  'orders.total-services': 'Total de serviços',
  'orders.observation': 'Observação',

  'orders.type': 'Tipo',
  'orders.type.budget': 'Orçamento',
  'orders.type.order': 'Pedido',
  'orders.type.sale': 'Venda',
  'orders.type.service': 'Serviço',
  'orders.type.purchase': 'Compra',
  'orders.type.undefined': 'Indefinido',

  'orders.status': 'Status',
  'orders.status.drafting': 'Rascunho',
  'orders.status.releasing': 'Esperando liberação',
  'orders.status.sorting': 'Esperando separação',
  'orders.status.checking': 'Esperando verificação',
  'orders.status.released': 'Liberado',
  'orders.status.undefined': 'Indefinido',

  'orders.situation': 'Situação',
  'orders.situation.opened': 'Aberto',
  'orders.situation.finished': 'Finalizado',
  'orders.situation.canceled': 'Cancelado',
  'orders.situation.undefined': 'Indefinido',

  'orders.subtype': 'Subtipo',
  'orders.subtype.budget': 'Orçamento',
  'orders.subtype.order': 'Pedido',
  'orders.subtype.pre-sale': 'Pré-venda',
  'orders.subtype.bonification': 'Bonificação',
  'orders.subtype.shipping': 'Remessa',
  'orders.subtype.exchange': 'Troca',
  'orders.subtype.purchase-order': 'Pedido de compra',
  'orders.subtype.sale': 'Venda',
  'orders.subtype.ready-delivery': 'Pronta entrega',
  'orders.subtype.service-order': 'Ordem de serviço',
  'orders.subtype.visit': 'Visita',
  'orders.subtype.purchase': 'Compra',
  'orders.subtype.undefined': 'Indefinido',

  'orders.message.product-already-created':
    'Este produto já foi lançado anteriormente!',

  'orders.message.confirm-order.title': 'Confirmar pedido?',
  'orders.message.confirm-order.description':
    'Ao confirmar o pedido, não poderá ser desfeito e o seu pedido não poderá ser alterado.',

  'orders.message.product-request.title': 'Produto indisponível',
  'orders.message.product-request.description':
    'Este produto está indisponível no momento. Iremos providenciar a compra assim que possível! Selecione uma das opções a seguir:',
  'orders.message.product-request.quantity': 'Adicione a quantidade:',

  'orders.message.product-request.please-select-one':
    'Selecione ao menos uma das opções',
  'orders.message.product-request.success': 'Aviso cadastrado com sucesso!',
  'orders.message.product-request.failure': 'Falha ao cadastrar aviso! 😢',

  // Orders report
  'orders.report.document.rg': 'RG: {value}',
  'orders.report.document.ie': 'IE: {value}',
  'orders.report.request-number': 'Número do pedido: {value}',
  'orders.report.date': 'Data: {value}',
  'orders.report.payment-method': 'Método de pagamento: {value}',

  'orders.report.observation.title': 'Observação',
  'orders.report.signature.title': 'Assinatura',

  'orders.report.item.description': 'Descrição',
  'orders.report.item.quantity': 'Quantidade',
  'orders.report.item.value': 'Valor',
  'orders.report.item.total': 'Total',
  'orders.report.item.unit-measure': 'Unid.',
  'orders.report.item.total-product': 'Total dos produtos',
  'orders.report.item.total-service': 'Total dos serviços',
  'orders.report.item.discount': 'Desconto',

  'orders.report.failure.get-customer-data':
    'Falha ao buscar os dados do cliente! 😢',

  'orders.report.failure.person-disabled':
    'Falha ao gerar relatório, pessoa desativada! 😢',
  'orders.report.failure.person-canceled':
    'Falha ao gerar relatório, pessoa cancelada! 😢',

  'orders.report.failure.seller-deleted':
    'Falha ao gerar relatório, vendedor excluído! 😢',
  'orders.report.failure.buyer-deleted':
    'Falha ao gerar relatório, comprador excluído! 😢',

  'orders.report.failure.seller-disabled':
    'Falha ao gerar relatório, vendedor desativado! 😢',
  'orders.report.failure.buyer-disabled':
    'Falha ao gerar relatório, comprador desativado! 😢',

  'orders.report.failure.customer-deleted':
    'Falha ao gerar relatório, cliente excluído! 😢',
  'orders.report.failure.supplier-deleted':
    'Falha ao gerar relatório, fornecedor excluído! 😢',

  'orders.report.failure.customer-disabled':
    'Falha ao gerar relatório, cliente desativado! 😢',
  'orders.report.failure.supplier-disabled':
    'Falha ao gerar relatório, fornecedor desativado! 😢',

  'orders.report.failure.no-items-created':
    'Falha ao gerar relatório, não há itens lançados! 😢',

  'orders.report.failure.payment-method-deleted':
    'Falha ao gerar relatório, método de pagamento excluído! 😢',

  // Page404
  'page404.title': 'Página não encontrada',

  'page404.404': 'Erro 404',
  'page404.description':
    'A página que você está procurando não foi encontrada.',
  'page404.return-to-website': 'Voltar ao site',

  // Payment Methods
  'payment-methods.get-payment-methods-failure':
    'Falha ao carregar os métodos de pagamento! 😢',

  // Phones
  'phones.title': 'Telefones',
  'phones.new-title': 'Novo telefone',
  'phones.edit-title': 'Edita telefone',

  'phones.number': 'Número',
  'phones.operator': 'Operadora',
  'phones.contact': 'Contato',
  'phones.call': 'Ligar para ',
  'phones.without-ddi': 'Sem DDI',
  'phones.select-ddi': 'Selecionar',

  'phones.type': 'Tipo',
  'phones.types.general': 'Geral',
  'phones.types.cell-phone': 'Celular',
  'phones.types.contact': 'Contato',
  'phones.types.fax': 'Fax',
  'phones.types.branch-office': 'Filial',
  'phones.types.landline': 'Fixo',
  'phones.types.headquarters': 'Matriz',
  'phones.types.residential': 'Residencial',
  'phones.types.work': 'Trabalho',

  'phones.is-whatsapp': 'WhatsApp',
  'phones.open-whatsapp': 'Abrir no WhatsApp',

  'phones.is-main': 'Principal',
  'phones.is-main-phone': 'Telefone principal',
  'phones.error-delete-is-main':
    'Telefone principal não pode ser excluído quando tem mais de um! 🤔',
  'phones.error.is-without-ddi-with-is-whatsapp':
    'Não é possível salvar um telefone sem ddi com WhatsApp!',

  // Products
  'products.deleted-product': 'Produto excluído',
  'products.canceled-product': 'Produto cancelado',

  'products.message.promotion-date': 'Em promoção de {start} até {end}',

  'products.get-products-failure': 'Falha ao carregar os produtos! 😢',

  // Profile
  'profile.title': 'Perfil',

  'profile.change-image': 'Alterar imagem',
  'profile.full-name': 'Nome completo',
  'profile.username': 'Nome de usuário',
  'profile.email': 'Email',

  'profile.current-password': 'Senha atual',
  'profile.new-password': 'Nova senha',
  'profile.confirm-password': 'Confirme sua nova senha',
  'profile.equal-passwords': 'A nova senha e a confirmação devem ser iguais',
  'profile.different-passwords':
    'A nova senha e a senha atual devem ser diferentes',

  // Reports
  'reports.failed-to-generate-report': 'Falha ao gerar o relatório! 😢',

  // Settings
  'settings.get-settings-failure': 'Falha ao carregar as configurações! 😢',

  // Sidebar
  'sidebar.menu': 'Menu',

  'sidebar.movements': 'Movimentos',
  'sidebar.movements.orders': 'Pedidos',

  // Sign In
  'sign-in.title': 'Bem-vindo!',

  'sign-in.description': 'Faça o login na sua conta para continuar',
  'sign-in.login': 'Email ou nome de usuário',
  'sign-in.password': 'Senha',
  'sign-in.remind-me': 'Lembrar-me',
  'sign-in.forgot-password': 'Esqueceu a senha?',
  'sign-in.sign-in': 'Entrar',

  'sign-in.toggle-password': 'Alterna a visibilidade da senha',
  'sign-in.authentication-failed':
    'Falha na autenticação, verifique seus dados! 🤔',
  'sign-in.company-failed': 'Usuário não pertence a nenhuma empresa! 🤔',

  // Sign Up
  'sign-up.title': 'Cadastro',

  'sign-up.description':
    'Entre com as suas informações para realizar o cadastro no sistema',
  'sign-up.full-name': 'Nome completo',
  'sign-up.username': 'Nome de usuário',
  'sign-up.email': 'Email',

  'sign-up.password': 'Senha',
  'sign-up.confirm-password': 'Confirme sua senha',
  'sign-up.equal-passwords': 'A senha e a confirmação devem ser iguais!',

  'sign-up.sign-up': 'Cadastrar',
  'sign-up.return': 'Voltar',

  'sign-up.register-success': 'Cadastrado com sucesso! 🎉',
  'sign-up.register-failed': 'Falha no cadastro, verifique seus dados! 🤔',

  // Switch company
  'switch-company.title': 'Troca de empresas',

  'switch-company.title-member':
    'Você é membro em {value, plural, =0 {0 empresa!} one {# empresa!} other {# empresas!}}',

  'switch-company.description': 'Selecione a empresa que você deseja acessar.',
  'switch-company.new-company': 'Nova empresa',
  'switch-company.type': 'Tipo',
  'switch-company.sign-out': 'Sair',

  // Table Localization
  'table-localization.body.add-tooltip': 'Adicionar',
  'table-localization.body.edit-tooltip': 'Editar',
  'table-localization.body.delete-tooltip': 'Excluir',
  'table-localization.body.empty-data-source-message':
    'Nenhum registro encontrado',
  'table-localization.body.delete-text':
    'Tem certeza que deseja excluir este registro?',
  'table-localization.body.save-tooltip': 'Salvar',
  'table-localization.body.cancel-tooltip': 'Cancelar',
  'table-localization.body.filter-tooltip': 'Filtrar',

  'table-localization.grouping.grouped-by': 'Agrupado por',
  'table-localization.grouping.placeholder':
    'Arraste os cabeçalhos para agrupar',

  'table-localization.header.actions': 'Ações',

  'table-localization.pagination.first-aria-label': 'Primeira página',
  'table-localization.pagination.first-tooltip': 'Primeira página',
  'table-localization.pagination.label-displayed-rows': 'de',
  'table-localization.pagination.label-rows-select': 'Registros',
  'table-localization.pagination.last-aria-label': 'Última página',
  'table-localization.pagination.last-tooltip': 'Última página',
  'table-localization.pagination.next-aria-label': 'Próxima página',
  'table-localization.pagination.next-tooltip': 'Próxima página',
  'table-localization.pagination.previous-tooltip': 'Página anterior',
  'table-localization.pagination.previous-aria-label': 'Página anterior',

  'table-localization.toolbar.add-remove-columns':
    'Adicionar ou remover colunas',
  'table-localization.toolbar.clear-search-aria-label': 'Limpar pesquisa',
  'table-localization.toolbar.export-aria-label': 'Exportar',
  'table-localization.toolbar.export-title': 'Exportar',
  'table-localization.toolbar.export-csv-name': 'Exportar como CSV',
  'table-localization.toolbar.export-pdf-name': 'Exportar como PDF',
  'table-localization.toolbar.n-rows-selected': 'linhas(s) selecionadas',
  'table-localization.toolbar.search-tooltip': 'Pesquisar',
  'table-localization.toolbar.search-placeholder': 'Pesquisar',
  'table-localization.toolbar.show-columns-title': 'Mostrar colunas',
  'table-localization.toolbar.show-columns-aria-label': 'Mostrar colunas',
};
