import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';

export default function PageTitle({ title }) {
  const { formatMessage } = useIntl();

  const titleData = `${title} ${title && ' - '} ${formatMessage({
    id: 'datasmart.smartcommerce',
  })}`;

  return <Helmet title={titleData} />;
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
