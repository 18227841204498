import { createSlice } from '@reduxjs/toolkit';

import userReducers from './userReducer';

const initialState = {
  detail: {},
  requestError: false,
  loading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: userReducers,
});

export const { actions: userActions } = userSlice;
export default userSlice.reducer;
