import PropTypes from 'prop-types';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ListItem, ListItemText, alpha } from '@mui/material';

export default function SidebarCategory({
  name,
  icon,
  isOpen,
  isCollapsable,
  ...rest
}) {
  return (
    <ListItem
      {...rest}
      sx={{
        paddingTop: 1.2,
        paddingBottom: 1.2,
        paddingLeft: 2.8,
        paddingRight: 1.7,
        '&:hover': {
          backgroundColor: theme =>
            alpha(theme.dashboardSidebar.header.background, 0.7),
        },
      }}
      disableRipple
    >
      {icon}
      <ListItemText
        sx={{
          marginLeft: 2,
        }}
      >
        {name}
      </ListItemText>
      {isCollapsable && (isOpen ? <ExpandMore /> : <ExpandLess />)}
    </ListItem>
  );
}

SidebarCategory.propTypes = {
  name: PropTypes.element.isRequired,
  icon: PropTypes.element,
  isOpen: PropTypes.bool,
  isCollapsable: PropTypes.bool.isRequired,
  badge: PropTypes.string,
};

SidebarCategory.defaultProps = {
  isOpen: false,
  badge: null,
  icon: null,
};
