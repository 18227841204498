import { useIntl } from 'react-intl';

import PropTypes from 'prop-types';

import { Menu } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';

export default function DrawerButton({ onDrawerToggle }) {
  const { formatMessage } = useIntl();

  return (
    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        aria-label={formatMessage({ id: 'header.open-drawer' })}
        onClick={onDrawerToggle}
      >
        <Menu />
      </IconButton>
    </Grid>
  );
}

DrawerButton.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};
