import PropTypes from 'prop-types';

import { Menu as MuiMenu } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function Menu({ children, handleCloseMenu, open, anchorEl }) {
  const theme = useTheme();

  return (
    <MuiMenu
      open={open}
      id="user-menu"
      anchorEl={anchorEl}
      onClick={handleCloseMenu}
      onClose={handleCloseMenu}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          mt: 1,
          boxShadow: 2,
          minWidth: 200,
          borderRadius: '8px',
          overflow: 'visible',
          border: '1px solid',
          color: theme.palette.mode === 'dark' ? 'grey.300' : 'grey.700',
          borderColor: theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
          backgroundColor:
            theme.palette.mode === 'dark' ? 'dark.main' : 'white.main',
          '&:before': {
            top: 0,
            right: 20,
            zIndex: 0,
            width: 10,
            height: 10,
            content: '""',
            display: 'block',
            position: 'absolute',
            borderTop: '1px solid',
            borderLeft: '1px solid',
            borderColor:
              theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
            bgcolor:
              theme.palette.mode === 'dark' ? 'dark.main' : 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
          },
        },
      }}
    >
      {children}
    </MuiMenu>
  );
}

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  handleCloseMenu: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.instanceOf(Element),
};

Menu.defaultProps = {
  anchorEl: null,
};
