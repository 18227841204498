import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { ListItem, ListItemText } from '@mui/material';

import IntlMessages from '~/utils/IntlMessages';

export default function FooterLink({ to, label, target }) {
  return (
    <ListItem
      sx={{
        width: 'auto',
      }}
    >
      <Link to={to} target={target}>
        <ListItemText
          primary={<IntlMessages id={label} />}
          sx={theme => ({
            color: theme.footer.color,
            '&:hover': {
              color: theme.palette.primary.main,
            },
          })}
        />
      </Link>
    </ListItem>
  );
}

FooterLink.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  target: PropTypes.string,
};

FooterLink.defaultProps = {
  to: '',
  label: '',
  target: '',
};
