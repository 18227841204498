import { createSlice } from '@reduxjs/toolkit';

import authReducers from './authReducers';

const initialState = {
  token: null,
  refreshToken: null,
  company: {},
  user: {},
  signed: false,
  selectedCompany: false,
  loading: false,
  signUpModalOpen: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: authReducers,
  extraReducers: builder => {
    builder.addCase('user/updateUserSuccess', (state, action) => {
      const { newUser } = action.payload;

      const { name, email, username } = newUser;

      const newData = {
        id: state.user.id,
        name,
        email,
        username,
      };

      const data = {
        ...state.user,
        ...newData,
      };

      state.user = data;
    });
  },
});

export const { actions: authActions } = authSlice;
export default authSlice.reducer;
