const authReducers = {
  // Sign in
  signInRequest: state => {
    state.loading = true;
  },
  signInSuccess: {
    reducer: (state, action) => {
      const { token, user, companies, refresh_token } = action.payload;

      state.user = user;
      state.token = token;
      state.refreshToken = refresh_token;
      state.signed = true;
      state.loading = false;

      if (companies.length === 1) {
        const systemUsernames = ['admin', 'suporte', 'replicador'];

        if (systemUsernames.includes(user.username)) {
          state.selectedCompany = false;
        } else {
          const [companyData] = companies;

          state.company = companyData;
          state.selectedCompany = true;
        }
      }
    },
    prepare: ({ token, user, companies, refresh_token }) => {
      return { payload: { token, user, companies, refresh_token } };
    },
  },
  signInFailure: state => {
    state.loading = false;
  },
  // Sign up
  signUpRequest: state => {
    state.laoding = true;
  },
  signUpSuccess: state => {
    state.loading = false;
    state.signUpModalOpen = false;
  },
  signUpFailure: state => {
    state.loading = false;
  },
  // Sign up modal
  signUpModalOpen: state => {
    state.signUpModalOpen = true;
  },
  signUpModalClose: state => {
    state.signUpModalOpen = false;
  },
  // Sign out
  signOut: state => {
    state.token = null;
    state.refreshToken = null;
    state.signed = false;
    state.user = {};
    state.selectedCompany = false;
    state.company = {};
  },
  signOutTokenInvalid: state => {
    state.token = null;
    state.refreshToken = null;
    state.signed = false;
    state.user = {};
    state.selectedCompany = false;
    state.company = {};
  },
  // Company selected
  setSelectedCompany: {
    reducer: (state, action) => {
      const { company } = action.payload;

      state.selectedCompany = true;
      state.company = company;
    },
    prepare: ({ company }) => {
      return { payload: { company } };
    },
  },
  clearSelectedCompany: state => {
    state.selectedCompany = false;
    state.company = {};
  },
  // Refresh token
  setRefreshToken: {
    reducer: (state, action) => {
      const { token } = action.payload;

      state.token = token;
    },
    prepare: ({ token }) => {
      return { payload: { token } };
    },
  },
};

export default authReducers;
