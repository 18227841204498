import { createSlice } from '@reduxjs/toolkit';

import productRequestReducers from './productRequestReducers';

const initialState = {
  loading: false,

  selectedProductId: null,
  selectedProductTitle: null,
  selectedProductQuantity: null,
};

const productRequestSlice = createSlice({
  name: 'productRequest',
  initialState,
  reducers: productRequestReducers,
  extraReducers: builder => {
    builder.addCase('orders/resetOrderDetail', state => {
      state.selectedProductId = null;
      state.selectedProductQuantity = null;
      state.selectedProductTitle = null;
    });
  },
});

export const { actions: productRequestActions } = productRequestSlice;
export default productRequestSlice.reducer;
