import { toast } from 'react-toastify';

import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { userFileActions } from '../userFiles/userFileSlice';
import { userActions } from './userSlice';

export function* updateUser({ payload }) {
  try {
    const { newUser, resetForm } = payload;

    const { data } = yield call(api.put, 'users', newUser);

    yield put(userActions.updateUserSuccess({ newUser: data }));

    if (resetForm) {
      resetForm();
    }

    toast.success(<IntlMessages id="messages.edit-success" />);
  } catch (err) {
    yield put(userActions.updateUserFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* getDetailsUser() {
  try {
    const { data } = yield call(api.get, 'users');

    const { files, ...rest } = data;

    yield put(userFileActions.getUserFiles({ files }));

    yield put(userActions.getUserDetailSuccess({ user: rest }));
  } catch (err) {
    yield put(userActions.getUserDetailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export default all([
  takeLatest('user/updateUserRequest', updateUser),
  takeLatest('user/getUserDetailRequest', getDetailsUser),
]);
