import React, { Component } from 'react';

import Loader from '../Loader';

const sleep = m =>
  new Promise(r => {
    setTimeout(r, m);
  });

export default function loadAsynchronousComponent(importComponent) {
  class AsyncComponent extends Component {
    _isMounted = false;

    constructor(props) {
      super(props);

      this.state = {
        component: null,
      };
    }

    async componentDidMount() {
      this._isMounted = true;
      await sleep(250);

      const { default: component } = await importComponent();

      if (this._isMounted) {
        this.setState({
          component,
        });
      }
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    render() {
      const { component: StateComponent } = this.state;

      return StateComponent ? <StateComponent {...this.props} /> : <Loader />;
    }
  }

  return AsyncComponent;
}
