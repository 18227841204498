import { Route } from 'react-router-dom';

import PropTypes from 'prop-types';

export default function renderRoutes(routes) {
  return routes.map(
    ({ children, path, component, layout, element: Element }) => {
      return children ? (
        children.map(
          ({
            path: childrenPath,
            component: childrenComponent,
            children: nestedChildren,
            layout: childrenLayout,
            element: ChildrenElement,
          }) => {
            if (nestedChildren) {
              return renderRoutes(nestedChildren);
            }

            return (
              <Route
                key={Date.now()}
                path={childrenPath}
                element={
                  <ChildrenElement
                    layout={childrenLayout}
                    component={childrenComponent}
                    path={childrenPath}
                  />
                }
              />
            );
          }
        )
      ) : (
        <Route
          key={Date.now()}
          path={path}
          element={
            <Element layout={layout} component={component} path={path} />
          }
        />
      );
    }
  );
}

renderRoutes.propTypes = {
  routes: PropTypes.shape({
    children: PropTypes.node,
    path: PropTypes.string,
    component: PropTypes.node,
    layout: PropTypes.node,
    element: PropTypes.element,
  }),
};
