import { Box, styled } from '@mui/material';

export const Root = styled(Box)(() => ({
  display: 'flex',
  minHeight: '100vh',
}));

export const AppContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}));

export const Content = styled(Box)(({ theme }) => ({
  transition: 'all 0.3s',
  flex: 1,
  backgroundColor: theme.body.dashboard.background,
  paddingTop: '3rem',
  paddingBottom: '2rem',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    maxWidth: 1200,
    margin: 'auto',
  },
  [theme.breakpoints.down(1550)]: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
}));
