import { Shuffle } from '@mui/icons-material';

import IntlMessages from '~/utils/IntlMessages';
import loadAsynchronousComponent from '~/components/loadAsynchronousComponent';
import DashboardLayout from '~/layouts/Dashboard';

import { PrivateRoute } from '../RoutesElements';

const OrderList = loadAsynchronousComponent(() =>
  import('~/pages/Orders/List')
);

export const movementsRoutes = {
  id: <IntlMessages id="sidebar.movements" />,
  header: <IntlMessages id="sidebar.menu" />,
  path: '/movements',
  icon: <Shuffle />,
  children: [
    {
      path: '/orders',
      component: OrderList,
      element: PrivateRoute,
      layout: DashboardLayout,
      name: <IntlMessages id="sidebar.movements.orders" />,
    },
  ],
};
