import { toast } from 'react-toastify';
import { redirect } from 'react-router-dom';

import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import api from '~/services/api';
import IntlMessages from '~/utils/IntlMessages';

import { orderActions } from './orderSlice';
import { orderItemActions } from '../orderItems/orderItemSlice';

export function* getOrders({ payload }) {
  try {
    const { queryParams, modalFilters, filterColumnsOrder, filterSearch } =
      payload;

    const { data } = yield call(api.get, `orders-customers/${queryParams}`);

    yield put(
      orderActions.getOrdersSuccess({
        data,
        modalFilters,
        filterColumnsOrder,
        filterSearch,
      })
    );
  } catch (err) {
    yield put(orderActions.getOrdersFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-failure" />);
  }
}

export function* createOrder({ payload }) {
  try {
    const { order, handleNavigateOnCreate, subResourceType } = payload;

    const { data } = yield call(api.post, 'orders-customers', order);

    yield put(orderActions.createOrderSuccess({ order: data }));

    yield put(orderActions.setSavingOrderOnSubResource({ subResourceType }));

    toast.success(<IntlMessages id="messages.save-success" />);

    handleNavigateOnCreate(data.id);
  } catch (err) {
    yield put(orderActions.createOrderFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.save-failure" />);
  }
}

export function* updateOrder({ payload }) {
  try {
    const { id, newOrder, updatingItem } = payload;

    const { data } = yield call(api.put, `orders/${id}`, newOrder);

    yield put(orderActions.updateOrderSuccess({ newOrder: data }));

    if (!updatingItem) {
      toast.success(<IntlMessages id="messages.edit-success" />);
    }
  } catch (err) {
    yield put(orderActions.updateOrderFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.edit-failure" />);
  }
}

export function* deleteOrder({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `orders/${id}`);

    yield put(orderActions.deleteOrderSuccess({ id }));

    redirect('/orders');

    toast.success(<IntlMessages id="messages.delete-success" />);
  } catch (err) {
    yield put(orderActions.deleteOrderFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.delete-failure" />);
  }
}

export function* getDetailsOrder({ payload }) {
  try {
    const { id } = payload;

    const { data } = yield call(api.get, `orders-customers/${id}`);

    const { items, ...rest } = data;

    yield put(orderActions.getOrderDetailSuccess({ order: rest }));

    yield put(orderItemActions.getOrderItems({ items }));

    const { shouldOpenSubResourceModal, subResourceType } = yield select(
      state => state.orders
    );

    if (shouldOpenSubResourceModal) {
      if (subResourceType === 'ITEM') {
        yield put(orderItemActions.openOrderItemModal());
      }

      yield put(orderActions.resetSubResourceData());
    }
  } catch (err) {
    yield put(orderActions.getOrderDetailFailure());

    if (err.isShownAPIErrorMessages) return;

    toast.error(<IntlMessages id="messages.get-details-failure" />);
  }
}

export function* resetDetailOrder() {
  yield put(orderItemActions.resetListOrderItem());
}

export default all([
  takeLatest('orders/getOrdersRequest', getOrders),
  takeLatest('orders/createOrderRequest', createOrder),
  takeLatest('orders/updateOrderRequest', updateOrder),
  takeLatest('orders/deleteOrderRequest', deleteOrder),
  takeLatest('orders/getOrderDetailRequest', getDetailsOrder),
  takeLatest('orders/resetOrderDetail', resetDetailOrder),
]);
