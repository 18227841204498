function formatSettingValues(setting, field_type) {
  let formattedValue = setting;

  if (field_type === 'BOOLEAN') {
    if (formattedValue === 'false') {
      formattedValue = false;
    } else if (formattedValue === 'true') {
      formattedValue = true;
    }
  }

  if (field_type === 'NUMBER') {
    formattedValue = Number(formattedValue);
  }

  return formattedValue;
}

const settingsReducers = {
  // Settings list
  getSettingsRequest: {
    reducer: state => {
      state.loading = true;
      state.requestError = true;
    },
    prepare: ({ systemCategoryId }) => {
      return {
        payload: {
          systemCategoryId,
        },
      };
    },
  },
  getSettingsSuccess: {
    reducer: (state, action) => {
      const { settings } = action.payload;

      const settingList = [];

      settings.forEach(setting => {
        const { items, ...rest } = setting;

        const eachItem = items.map(item => ({
          ...item,
          setting_name: setting.name,
          parent_id: setting.id,
          level: 2,
        }));

        const newSetting = {
          ...rest,
          level: 1,
        };

        settingList.push(newSetting);
        settingList.push(...eachItem);
      });

      const sortedList = settingList.sort((a, b) => {
        if (a.level === 1 && b.level === 1) {
          return a.type < b.type && a.type !== b.type ? -1 : 1;
        }

        if (a.level === 2 && b.level === 2) {
          return a.type < b.type && a.type !== b.type ? -1 : 1;
        }

        return 0;
      });

      state.list = sortedList;
      state.loading = false;
      state.requestError = false;
    },
    prepare: ({ settings }) => {
      return {
        payload: {
          settings,
        },
      };
    },
  },
  getSettingsFailure: state => {
    state.loading = false;
  },
  // Reset list
  resetSettingsList: state => {
    state.list = [];
    state.requestError = false;
    state.loading = false;
  },
  // Set global list
  setGlobalSettingsListSuccess: {
    reducer: (state, action) => {
      const { settings } = action.payload;

      const settingList = [];

      settings.forEach(setting => {
        const { items, field_type } = setting;

        const eachItems = items.map(item => ({
          ...item,
          setting_name: setting.name,
        }));

        const filteredItems = eachItems.filter(item => item.type !== 'MOBILE');

        const formattedSettings = filteredItems.map(item => {
          const formattedValue = formatSettingValues(item.value, field_type);

          const data = {
            id: item.id,
            setting_name: item.setting_name,
            setting_id: setting.id,
            display_label: setting.display_label,
            value: formattedValue,
            type: item.type,
            field_type,
          };

          return data;
        });

        settingList.push(...formattedSettings);
      });

      state.globalList = settingList;
    },
    prepare: ({ settings }) => {
      return { payload: { settings } };
    },
  },
  setGlobalSettingsListFailure: state => {
    state.globalList = [];
  },
  // Reset global list
  resetGlobalSettingList: state => {
    state.globalList = [];
  },
};

export default settingsReducers;
