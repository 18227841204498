import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import { ListItem, ListItemText } from '@mui/material';
import { alpha } from '@mui/material/styles';

// import { Link, LinkText, LinkBadge } from './styles';

// const NavLink = React.forwardRef((props, ref) => (
//   <RouterNavLink innerRef={ref} {...props} />
// ));

export default function SidebarLink({ name, to }) {
  return (
    <ListItem
      button
      dense
      component={RouterNavLink}
      to={to}
      sx={{
        paddingLeft: 8,
        color: theme => alpha(theme.dashboardSidebar.color, 0.7),
        '&:hover': {
          backgroundColor: theme =>
            alpha(theme.dashboardSidebar.header.background, 0.7),
        },
      }}
      disableRipple
    >
      <ListItemText>{name}</ListItemText>
    </ListItem>
  );
}

SidebarLink.propTypes = {
  name: PropTypes.element.isRequired,
  to: PropTypes.string.isRequired,
};
